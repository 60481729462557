$top-row-size: 280px;
$background: #000;
$foreground: #fff;
$separator: #333;

html, body {
  // Disable scrolling and body bounce on iOS
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

* {
  user-select: none;
}

body {
  margin: 0;
  font-family: sans-serif;
}

.weather-dash {
  color: $foreground;
  background-color: $background;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: $top-row-size 1fr;
  grid-template-areas:
    "clock clock weather weather hourly hourly"
    "forecast forecast forecast map map map";

  position: absolute;
  width: 100%;
  height: 100%;
}

.clock-widget {
  $clock-size: $top-row-size;

  grid-area: clock;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $clock-size;

  .clock {
    padding: 0 10px 0 0;
    margin: 0;
    width: $clock-size;
    height: $clock-size;

    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  time {
    font-size: 100px;
    position: absolute;
  }

  .second-tick {
    $second-tick-width: 3px;
    $second-tick-height: 8px;

    display: inline-block;
    width: $second-tick-width;
    height: $second-tick-height;
    border-radius: min($second-tick-width, $second-tick-height) / 2;
    background: $foreground;
    opacity: 0.3;
    transition: opacity 0.2s ease;

    position: absolute;

    @for $i from 0 to 60 {
      &:nth-child(#{$i + 1}) {
        transform: rotate($i * 6deg) translateY((-$clock-size / 2) + $second-tick-height);
      }
    }

    &.second-tick-illuminated {
      opacity: 1
    }
  }
}

.weather-map-widget {
  grid-area: map;
  width: 100%;
  height: 100%;

  .leaflet-container {
    width: 100%;
    height: 100%;
  }
}

.current-weather-widget {
  grid-area: weather;
  display: flex;

  .current-weather {
    flex-grow: 1;

    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .current-conditions {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
    }

    .current-temp {
      font-size: 100px;
    }

    .current-extra {
      font-size: 28px;
      opacity: 0.3;
    }
  }
}

.hourly-forecast-widget {
  grid-area: hourly;
  display: flex;

  .hourly-forecast {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .current-date {
      display: block;
      text-align: center;
      padding: 4px 12px;
      font-size: 60px;
    }

    ul.hourly-list {
      overflow: scroll;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        list-style: none;
        font-size: 28px;
        padding: 4px 12px;
        height: 42px;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          border-bottom: 1px solid $separator;
        }

        .hourly-conditions {
          display: flex;
          align-items: center;
        }

        .hourly-conditions-icon {
          height: 2em;
        }
      }
    }
  }

  // TODO: Do we want this?
  .block-temp {
    .temp {
      position: relative;

      .deg {
        position: absolute;
      }
    }
  }
}

.weather-forecast-widget {
  grid-area: forecast;
  display: flex;

  .daily-forecast {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      border-right: 1px solid $separator;
    }

    .forecast-date {
      font-size: 32px;
    }

    .forecast-weather {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .forecast-conditions {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 20px 0;
      font-size: 14px;
    }

    .forecast-extra {
      opacity: 0.3;
    }

    .forecast-hi-lo-temp {
      font-size: 20px;
    }

    .forecast-details {
      font-size: 12px;
    }
  }
}
